import React from "react";
import CopyrightPolicy from "../views/CopyrightPolicy/CopyrightPolicy";
import Layout from "../components/Layout";

function copyrightPolicy({location}) {
  return (
    <div>
      <Layout location={location}>
        <CopyrightPolicy />
      </Layout>
    </div>
  );
}

export default copyrightPolicy;
