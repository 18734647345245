import React from "react";

function CopyrightPolicy() {
  return (
    <div>
      <div className="max-w-7xl m-auto px-8">
        <div className="w-full flex justify-center ">
          <h1 className="text-lg font-bold md:mb-12 mb-8 mt-8">
            Copyright Policy
          </h1>
        </div>
        <h1 className="text-md font-bold md:mb-8 mb-6">
          Termination of Internet Use Privileges for Infringement and Contact
          Information for Suspected Copyright or Intellectual Property
          Infringement.
        </h1>
        <p className="text-md  md:mb-8 mb-6">
          As described below, Vonage will terminate the privileges of any user
          who uses his or her internet access to unlawfully download or transmit
          copyrighted material without a license, or in any way infringe the
          intellectual property rights of any party, without a valid defense or
          fair use privilege to do so. After proper notification by the
          copyright holder or intellectual property owner or its agent to
          Vonage, and later confirmation through court order or an admission by
          the user regarding unlawful infringement, Vonage will terminate the
          infringing IP Address. Vonage may also in its sole discretion decide
          to suspend and/or terminate an IP Address prior to that time if it has
          good faith belief that infringement has in fact occurred.
        </p>
        <p className="text-md  md:mb-8 mb-6">
          If you believe that your copyright or intellectual property rights
          have been infringed through the use of a Vonage user or IP Address,
          please contact our Designated Agent for notice of claims as follows:
        </p>
        <p className="text-md  md:mb-8 mb-6">
          By Mail: Vonage, 23 Main Street, Holmdel, NJ 07733, Attn: Legal
          Department
        </p>
        <p className="text-md  md:mb-8 mb-6">
          By Phone:
          <span className="mx-2 cursor-pointer border-b-1 border-black">
            (732) 444-2892
          </span>
          By Fax: (732) 202-5221 By Email:
          <span className="mx-2 mb-5 cursor-pointer border-b-1 text-blue-primary border_b_blue">
            {" "}
            IPCorrespondence@vonage.com{" "}
          </span>{" "}
          <br />
          7/6/2020, 10:30:59 PM
        </p>
      </div>
    </div>
  );
}

export default CopyrightPolicy;
